<template>
  <AmericanFootballListOfPlayers
    v-if="isAmericanFootball"
    :team-data="teamData"
    :event-id="eventId"
    @onPlayersTypeTabChange="onPlayersTypeTabChange"
  />
  <CommonSportsListOfPlayers
    v-else
    :team-data="teamData"
    :event-id="eventId"
  />
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import CommonSportsListOfPlayers from './CommonSportsListOfPlayers';
import AmericanFootballListOfPlayers from './american-football/AmericanFootballListOfPlayers';
import sportIds from '@/services/helpers/sports';

const {
  FOOTBALL_ID,
} = sportIds;

export default {
  emits: ['onPlayersTypeTabChange'],
  components: {
    CommonSportsListOfPlayers,
    AmericanFootballListOfPlayers,
  },
  props: {
    teamData: {
      type: Object,
      default: () => {},
    },
    eventId: {
      type: String,
      default: () => '',
    },
  },
  setup(_, { emit }) {
    const store = useStore();
    const isAmericanFootball = computed(() => store.getters.playerSetupData?.sportId === FOOTBALL_ID);
    const onPlayersTypeTabChange = (newActiveTab) => {
      emit('onPlayersTypeTabChange', newActiveTab);
    };
    return {
      isAmericanFootball,
      onPlayersTypeTabChange,
    };
  },
};
</script>

<style lang="scss">
.list-of-players {
  height: 50%;
  padding-top: 16px;
  position: relative;
  .list-of-players-disabled-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, .5);
    z-index: 120;
  }
  .section-title {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 8px;
    text-transform: uppercase;
    color: $primary500;
  }
  .section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32px;
    padding-right: 3px;
    margin-bottom: 12px;
    .title {
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
    }
  }
  .actions-section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;

    .suspend-btn {
      &.suspend-active {
        color: $error500;

        .icon {
          svg path {
            fill: $error500;
          }
        }
      }
    }
    .add-players-btn {
      .icon {
        svg path {
          fill: $white;
        }
      }
    }
  }
  .section-content {
    height: calc(100% - 55px);
    .table-data {
      height: calc(100% - 22px);
      .no-data-message {
        width: 55%;
        margin: 0 auto;
        text-align: center;
        padding: 32px 0;
        p {
            line-height: 17px;
            text-align: center;
            color: $gray700;
        }
        .button {
            margin-top: 16px;
            span {
                font-size: 14px;
                line-height: 17px;
            }
            .icon {
                svg path {
                    stroke: $white;
                }
            }
        }
      }
    }
  }
}
</style>
