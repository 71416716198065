<template>
  <div class="player-by-team-setup">
    <ListOfPlayers
      :team-data="teamData"
      :event-id="event?.eventId"
      @onPlayersTypeTabChange="onPlayersTypeTabChange"
    />
    <MarketsPerPlayer
      :team-data="teamData"
      :competition-type="event?.competitionType"
      :player-type-active-tab="activePlayerTypeTab"
    />
  </div>
</template>

<script>
import { ref } from 'vue';
import ListOfPlayers from './ListOfPlayers';
import MarketsPerPlayer from './MarketsPerPlayer';

export default {
  components: {
    ListOfPlayers,
    MarketsPerPlayer,
  },
  props: {
    teamData: {
      type: Object,
      default: () => {},
    },
    event: {
      type: Object,
      default: () => ({}),
    },
    homeAwayLabel: {
      type: String,
      default: '',
    },
  },
  setup() {
    const activePlayerTypeTab = ref('quarterbacks');
    const onPlayersTypeTabChange = (newActiveTab) => {
      activePlayerTypeTab.value = newActiveTab;
    };

    return {
      activePlayerTypeTab,
      onPlayersTypeTabChange,
    };
  },
};
</script>

<style lang="scss">
.player-by-team-setup {
  padding: 0 16px 16px 16px;
}
</style>
